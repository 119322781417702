import ApplicationController from "@/controllers/application_controller"
import { ResultsStoreModule } from "@/modules/results_store_module"

export default class extends ApplicationController {
  static targets = ["input"]
  static values = {
    questionResultId: String,
  }
  async initialize() {
    this.storeModule = await ResultsStoreModule("questions_results")
  }

  connect() {
    this.setQuestionResultToInputValue()
    window.addEventListener("storage", (e) => {
      console.log("storage updated", e)
      this.setQuestionResultToInputValue()
    })
  }

  async setQuestionResultToInputValue() {
    if (!this.storeModule) return false
    await this.storeModule.init(() => {
      this.questionResult = this.storeModule.getResultByQuestionId(
        this.questionResultIdValue
      )
      console.log("this.questionResult", this.questionResult)
      this.inputTarget &&
        this.inputTarget?.setAttribute("value", this.questionResult)
    })
  }
}
