import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static classes = ["active"]

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  initialize() {}

  connect() {}

  handleAnswer(event) {
    event.preventDefault()
    this.clearAnswers()
    const discEl = event.target.querySelector(".answer-disc")
    if (discEl) discEl.classList.add(this.activeClass)
    this.answerController.getAnswerData(event.target)
    this.answerController.handleAnswer()
  }

  clearAnswers() {
    const discEls = [...this.element.querySelectorAll(".answer-disc")]
    discEls.forEach((discEl) => {
      discEl.classList.remove(this.activeClass)
    })
  }
}
