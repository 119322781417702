import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";

export default class extends ApplicationController {
  trackerModule = new EventTrackerModule(ahoy)
  initialize(){
    console.log('button_component base_component initialize')
  }
  connect(){
    console.log('button_component base_component connect')
  }
  log() {
    console.log('button_component base_component here')
  }
  click() {
    this.trackButtonClick()
  }
  trackButtonClick(){
    this.trackerModule.track('button_component_click', {
      'url_params': window.location.search,
      'page': window.location.pathname,
      'url': window.location.href
    })
  }
}