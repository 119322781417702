import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";

export default class extends ApplicationController {
  static targets = ['input', 'map']

  async initialize(){
    this.service = new google.maps.places.AutocompleteService()
    this.detailsService = new google.maps.places.PlacesService(this.mapTarget)
    this.sessionToken = false
    const userStoreModule = await ResultsStoreModule('savedUser')
    const userStoreObject = await userStoreModule.getStore("savedUser") || {}
    this.initialZip = userStoreObject.zip
    console.log('[LeadForms::Field::Zipcode::Component::initialize]', this.detailsService)
  }

  connect(){
    console.log('[LeadForms::Field::Zipcode::Component::connect]', true)
    window.addEventListener("storage", async (e) => {
      const userStoreModule = await ResultsStoreModule('savedUser')
      const userStoreObject = await userStoreModule.getStore("savedUser") || {}
      const zip = userStoreObject.zip
      if (zip && this.initialZip !== zip) {
        this.inputTarget.value = zip
        this.initialZip = zip
      }
    })
  }

  handleZipChange = async e => {
    const target = e.target
    const val = target.value
    console.log('handleZipChange', val)
    const isValid = this.validatorController.checkPatternValidation(e)
    console.log('IS_VALID', isValid)
    if (isValid) {
      if (!this.sessionToken) this.refreshSessionToken()
      const {predictions} = await this.service.getPlacePredictions(
        { input: val, sessionToken: this.sessionToken, componentRestrictions: { country: ["us"] }, types: ['postal_code'], fields: ["address_components"] },
        (items, status) => this.handlePrediction(items, status)
      )
      if (predictions.length > 0 ) this.leadFormController.submitForm(e)
    }
    return
  }

  handlePrediction = async (predictions, status) => {
    let place = false
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      console.error('GOOGLE PLACES SERVICE', status)
      this.validatorController.addErrorState()
      return
    }
    if (predictions.length > 0) {
      const firstPrediction = predictions[0]
      const placeId = firstPrediction.place_id
      place = await this.getStateByPlaceId(placeId)
      this.refreshSessionToken()
    } else {
      this.validatorController.addErrorState()
    }
    return place
  } 

  getStateByPlaceId = async (placeId) => {
    const saveToUserStore = async (key, text) => {
      this.userStoreModule = await ResultsStoreModule('savedUser')
      await this.userStoreModule.init()
      await this.userStoreModule.saveAnswer(key, text)
    }
    const request = {
      placeId: placeId,
      fields: ['address_component']
    }
    const saveStateToUserStore = async (place, _status) => {
      console.log('[LeadForms::Field::Zipcode::Component::getStateByPlaceId]', place)
      const stateData = place['address_components'].find( i => i.types.includes("administrative_area_level_1") )
      const cityData = place['address_components'].find( i => i.types.includes("locality") )
      const shortState = stateData['short_name']
      const city = cityData['long_name']
      saveToUserStore('state', shortState)
      saveToUserStore('city', city)
      this.refreshAgencies()
      return stateData
    }
    return await this.detailsService.getDetails(request, saveStateToUserStore)
  }

  refreshAgencies = async () => {
    const agenciesElements = [...document.querySelectorAll('[data-controller="chain--answer--solar-buttons--component chain--answer--component"]')]
    agenciesElements.map( el => {
      const controller = this.application.getControllerForElementAndIdentifier(el, "chain--answer--solar-buttons--component")
      controller.populateByState()
    })
  }

  refreshSessionToken = () => {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken()
  }

  get validatorController() {
    return this.application.getControllerForElementAndIdentifier(this.inputTarget, "field-validation")
  }

  get leadFormController() {
    const parentFormComponent = this.element.closest('[data-controller="lead-forms--component"]')
    return this.application.getControllerForElementAndIdentifier(parentFormComponent, "lead-forms--component")
  }

}