import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
import { ResultsStoreModule } from "@/modules/results_store_module";
import { ChainParamConditionModule } from "@/modules/chain_param_conditions_module";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
import SparkMD5 from "spark-md5";

export default class extends ApplicationController {
  static values = {
    finalUrl: String,
    id: String,
    step: Number,
    stepIndex: Number,
    stepsCount: Number,
    conditions: Array,
    servicename: String,
    noScrollToTop: String,
  }
  static targets = ["progressBar", "progressCounter", "questionsWrapper", "question"]
  linkModule = new LinkModule()
  trackerModule = new EventTrackerModule(ahoy)

  get progressbarController() {
    if (this.hasProgressBarTarget) {
      return this.application.getControllerForElementAndIdentifier(this.progressBarTarget, "chain--progress--component")
    }
  }

  get stepsBarController() {
    if (this.hasProgressBarTarget) {
      return this.application.getControllerForElementAndIdentifier(this.progressBarTarget, "chain--progress--steps-component")
    }
  }

  initialize() {
    const domain = window.location.host
    const uniqSeed = this.servicenameValue || this.idValue
    this.trackingId = SparkMD5.hash(uniqSeed + domain)
    this.addChainTrackingIdToQuestions()
    this.element.classList.add("transition-all", "ease-in", "duration-500", "opacity-0")
    this.applyParamConditions()
  }

  connect() {
    this.element.classList.remove("opacity-0")
    this.element.classList.add("opacity-1")
    this.stepValue = 1
    this.stepIndexValue = 0
    this.trackRender()
  }

  addChainTrackingIdToQuestions() {
    this.questionTargets.map((question) => {
      question.dataset.trackingChainId = this.trackingId
    })
  }

  trackRender() {
    this.trackerModule.track("question_chain_render", {
      tracking_chain_id: this.trackingId,
      servicename: this.servicenameValue,
      steps_count: this.stepsCountValue,
      question_chain_id: this.idValue,
      conditions: this.conditionsValue,
      page: window.location.pathname,
      url: window.location.href,
    })
  }

  async applyParamConditions() {
    if (this.hasConditionsValue) {
      const currentParams = Object.fromEntries(new URLSearchParams(window.location.search))
      const conditionsModule = new ChainParamConditionModule(this.conditionsValue, currentParams, this.questionTargets)
      await conditionsModule.checkConditions()
      this.connectCurrentQuestion()
    }
  }

  nextStep() {
    if (this.stepValue >= this.questionTargets.length) {
      this.performFinalRedirect()
    } else {
      this.stepValue++
      this.stepIndexValue++
    }
  }

  prevStep() {
    if (this.stepValue > 1) {
      this.stepValue--
      this.stepIndexValue--
    }
  }

  stepValueChanged() {
    console.log("STEP VALUE CHANGED", this.stepValue)
    const stepsCount = this.questionTargets.length
    const progressPercent = parseInt((this.stepValue / stepsCount) * 100)
    if (this.progressbarController && this.hasProgressBarTarget) {
      this.progressbarController.updateStep(this.stepValue, stepsCount)
      this.progressbarController.updatePercent(progressPercent)
    }

    if (this.stepsBarController && this.hasProgressBarTarget) {
      this.stepsBarController.updateStep(this.stepValue, stepsCount)
    }

    this.connectCurrentQuestion()
    this.slideToNextQuestion()
  }

  async performFinalRedirect() {
    const formUrlParams = (obj) =>
      "?" +
      Object.keys(obj)
        .map((key) => {
          return obj[key] ? key + "=" + obj[key] : ""
        })
        .join("&")
    if (this.finalUrlValue) {
      this.userStoreModule = await ResultsStoreModule("savedUser")

      const finalUrlVal =
        this.finalUrlValue === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.finalUrlValue

      const userStoreObject = await this.userStoreModule.getStore("savedUser")
      let finalUrl = this.linkModule.addCurrentParamsToUrl(finalUrlVal)
      finalUrl = new URL(this.linkModule.addParamsToUrl(finalUrl)(formUrlParams(userStoreObject)))
      if (finalUrl.origin == window.location.origin) {
        this.linkModule.performRedirect(finalUrl.toString())
      } else {
        this.linkModule.performRedirect(finalUrl.toString())
      }
    }
  }

  getStepWidth(baseWidth, count, ext) {
    return `${+baseWidth * +count}${ext}`
  }

  slideToNextQuestion() {
    let stepSize = 100
    let stepExt = "vw"
    if (this.questionTargets[0]) {
      stepSize = this.questionTargets[0].offsetWidth
      stepExt = "px"
    }
    this.stepWidth = this.getStepWidth(stepSize, this.stepIndexValue, stepExt)
    this.questionsWrapperTarget.style.transform = `translateX(-${this.stepWidth})`
    const noScrollToTop = this.hasNoScrollToTopValue && (this.noScrollToTopValue == "true" || this.noScrollToTopValue == 'enable')
    if (!noScrollToTop) {
      window.scrollTo(0, 0)
    }
  }

  connectCurrentQuestion() {
    if (this.stepValue === 0) return false
    const questionControllerName = " chain--question--component"
    this.questionTargets.forEach((element, index) => {
      if (this.stepIndexValue === index) {
        if (element.dataset.controller.includes(questionControllerName)) return
        element.dataset.controller += questionControllerName
      } else {
        element.dataset.controller = element.dataset.controller.replace(questionControllerName, "")
      }
    })
  }
}
