import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["dropdown"]

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  initialize() {}

  connect() {}

  handleAnswer(event) {
    event.preventDefault()
    const options = event.target.options
    const answer = options[options.selectedIndex]
    this.answerController.getAnswerData(answer)
    this.answerController.handleAnswer()
  }

  handleSubmit(event) {
    event.preventDefault()
    const options = this.dropdownTarget.options
    const answer = options[options.selectedIndex]
    if (this.dropdownTarget.reportValidity()) {
      this.answerController.getAnswerData(answer)
      this.answerController.handleAnswer()
    }
  }
}
