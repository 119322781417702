import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    token: String,
  }
  initialize() {
    console.log("snippets--predefined-templates--fbq-uuid-to-sub-five-controller", this.tokenValue)
  }
  connect() {
    console.log("snippets--predefined-templates--fbq-uuid-to-sub-five-controller", this.tokenValue)
  }
}
