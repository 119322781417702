import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["form"]

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  initialize() {}

  connect() {}

  handleAnswer = () => {
    if (this.answerController.questionController) {
      this.answerController.questionController.triggerModal()
      this.answerController.questionController.triggerFloater()
    }
    this.answerController.chainController.nextStep()
  }
}

 