import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"
import ApplicationController from "@/controllers/application_controller"

const trackerModuleInst = new EventTrackerModule(ahoy)
export default class extends ApplicationController {
  pixelModule

  initialize() {
    this.pixelModule = trackerModuleInst
  }
  connect() {
    super.connect()
  }
}
