import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    token: String
  }
  initialize(){
    console.log('jlid predefined template init', this.tokenValue)
  }
  connect(){
    console.log('jlid predefined template  connect', this.tokenValue)
  }
}
