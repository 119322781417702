import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  answerStore = {}
  static targets = ["rangeAnswer", "rangeInput", "currentAnswer"]
  initialize() {
    this.updateCurrentAnswer()
  }
  connect() {}

  handleAnswer(event) {
    event.preventDefault()
    this.answerController.getAnswerData(this.answerStore)
    this.answerController.handleAnswer()
  }

  handleRangeChange() {
    this.updateCurrentAnswer()
  }

  updateCurrentAnswer() {
    this.currentAnswerTarget.textContent = this.rangeInputTarget?.value
    this.answerStore = {
      dataset: {
        text: this.rangeInputTarget?.value,
      },
    }
  }

  get currentAnswerElement() {
    return this.rangeAnswerTargets[this.rangeInputTarget.value]
  }

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "chain--answer--component"
    )
  }
}
