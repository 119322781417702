import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  initialize(){
    console.log('Answer::InsuranceSelect::ComponentController[index]', this.element)
  }
  connect(){
    console.log('Answer::InsuranceSelect::ComponentController[connect]', this.element)
  }

  handleAnswer(event) {
    event.preventDefault()
    console.log('Answer::InsuranceSelect::ComponentController#handleAnswer]', event)
    const options = event.target.options
    const answer = options[options.selectedIndex]
    this.answerController.getAnswerData(answer)
    this.answerController.handleAnswer()
  }


  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }
}
