import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [ "question", "checkboxSubmit" ]

  initialize(){
    console.log('Answer::CheckboxComponent::Controller[index]', this.element)

  }
  connect(){
    console.log('Answer::CheckboxComponent::Controller[connect]')
  }

  async handleSubmit(event) {
    console.log('CHECKBOX SUBMIT', this.questionTargets)

    const promiseChain = funcs =>
      funcs.reduce((promise, func) =>
        promise.then(result => func().then(Array.prototype.concat.bind(result))),
      Promise.resolve([]))

    const checkboxFuncs = this.questionTargets.map( answer => () => this.checkboxQuestionController(answer).handleAnswer())
    await promiseChain(checkboxFuncs) //sequentially trigger checkbox questions handlers

    this.answerController.getAnswerData(this.checkboxSubmitTarget)
    this.answerController.handleAnswer()
  }

  checkboxQuestionController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "chain--answer--checkbox--question--component")
  }

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

}
