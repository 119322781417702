import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    items: Array,
    step: Number,
  }
  static targets = ["item"]


  initialize(){
    console.log('[Shared::Loader::TextComponent::initialize]', this.itemsValue)
    const observer = new window.IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.stepValue = 1
        return
      }
    }, {
      root: document.body,
      rootMargin:'0px',
      threshold: 0.9, // set offset 1 means trigger if atleast 50% of element in viewport
    })
    
    observer.observe(this.element);
  }

  connect(){
    console.log('[Shared::Loader::TextComponent::connect]', this.itemTargets)
    
  }

  disconnect(){
    console.log('[Shared::Loader::TextComponent::disconnect]', this.element)
  }

  nextItem() {
    console.log("NEXT_STEP_TRIGGER")
    if (this.stepValue >= this.itemTargets.length) {
      console.log('LAST LOADER STEP')
      this.questionController && this.questionController.chainController.nextStep()
    } else {
      this.stepValue++
    }
  }

  stepValueChanged() {
    console.log("STEP VALUE CHANGED", this.stepValue)
    this.connectCurrentItem()
  }

  connectCurrentItem() {
    if (this.stepValue === 0) return false
    const itemControllerName = " shared--loader--text-item--component"
    this.itemTargets.forEach((element, index) => {
      const stepIndex = this.stepValue - 1
      if (stepIndex  === index) {
        if (element.dataset.controller.includes(itemControllerName)) return
        element.dataset.controller += itemControllerName
      } else {
        if (false) element.dataset.controller = element.dataset.controller.replace(itemControllerName, "")
      }
    })
  }


  get questionController() {
    const parentQuestion = this.element.closest('.lander-item')
    if (!parentQuestion) return false

    return this.application.getControllerForElementAndIdentifier(
      parentQuestion,
      "chain--question--component"
    )
  }

}