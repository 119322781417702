import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    delay: Number,
  }

  get loaderController() {
    return this.application.getControllerForElementAndIdentifier(this.parentLoader, "shared--loader--text-component")
  }

  initialize() {
    this.DEFAULT_DELAY = 1800
    this.parentLoader = this.element.closest(".loader")
    console.log("[Shared::Loader::TextItem::Component::initialize]", this.element)
    this.showElement()
    setTimeout(() => {
      this.hideElement()
    }, this.delayValue)
  }

  connect() {
    console.log("[Shared::Loader::TextItem::Component::connect]", this.delayValue)
  }

  showElement() {
    // this.element.classList.remove("opacity-0")
    const fadeInClass = "animate-in fade-in zoom-in duration-1000 ease-out"
    this.element.classList.add(...fadeInClass.split(" "))
    setTimeout(() => {
      this.element.classList.remove("opacity-0")
      this.element.classList.remove("hidden")
    }, 100)
  }

  hideElement() {
    // const fadeInClass = "animate-in fade-in"
    // const fadeOutClass = "animate-out fade-out"
    // this.element.classList.remove(...fadeInClass.split(" "))
    // this.element.classList.add(...fadeOutClass.split(" "))
    setTimeout(() => {
      this.element.classList.add("hidden")
      this.loaderController.nextItem()
    }, 1000)
  }

  disconnect() {
    console.log("[Shared::Loader::TextItem::Component::disconnect]", this.element)
  }
}
