import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["tile"]

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  initialize() {
    this.initButtonsOverlay()
  }

  connect() {}

  handleAnswer(event) {
    event.preventDefault()
    this.answerController.getAnswerData(event.target)
    this.answerController.handleAnswer()
  }

  initButtonsOverlay() {
    const classes =
      "relative after:content-[''] after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:bg-transparent"
    const tiles = [...this.tileTargets]
    tiles.map((tile) => {
      tile.classList.add(...classes.split(" "))
    })
  }
}
