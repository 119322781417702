import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  initialize(){
    console.log('editableLayoutComponent initialize')
  }
  connect(){
    console.log('editableLayoutComponent connect')
  }
  log() {
    console.log('editableLayoutComponent here')
  }
}