import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";

export default class extends ApplicationController {
  static targets = ["yearsSelect", "makesSelect" ,"modelsSelect", "makesButtons", "modelsButtons",
    "yearsStep", "makesStep", "modelsStep" ,"currentSelection", "submitButton"]
  static values = { listId: String }
  static classes = [ "hidden" ] 

  initialize(){
    console.log('Answer::CarButtonsController[index]', this.element)
    this.currentStep = 'year'
    this.yearSelection
    this.makeSelection
    this.modelSelection
    // this.populateMakes()
  }
  connect(){
    console.log('Answer::CarButtonsController[connect]')
  }

  handleQuestionSubmit = (target) => {
    this.answerController.getAnswerData(target)
    this.answerController.handleAnswer()
  }

  saveDataToStore = async () => {
    this.resultsStoreModule = await ResultsStoreModule()
    await this.resultsStoreModule.init()
    await this.resultsStoreModule.saveAnswer('carYear', this.yearSelection)
    await this.resultsStoreModule.saveAnswer('carMake', this.makeSelection)
    await this.resultsStoreModule.saveAnswer('carModel', this.modelSelection)
  }

  handleYearSelect = e => {
    e.preventDefault()
    const target = e.target
    const prevSelection = this.yearsStepTarget.querySelector('.selected')
    if (prevSelection) prevSelection.classList.remove('selected')
    target.classList.add('selected')
    
    this.yearSelection = target.getAttribute('data-value') || target.value
    if (!this.yearSelection) return
    this.currentSelectionTargets.map( curr => curr.innerText = this.yearSelection)
    
    
    this.populateMakes(this.yearSelection)
    this.changeStep('make')
  }

  handleMakeSelect = e => {
    e.preventDefault()
    const target = e.target
    const prevSelection = this.makesStepTarget.querySelector('.selected')
    if (prevSelection) prevSelection.classList.remove('selected')
    target.classList.add('selected')

    this.makeSelection = target.getAttribute('data-value') || target.value
    if (!this.makeSelection) return
    this.currentSelectionTargets.map( curr => curr.innerText = `${this.yearSelection} ${this.makeSelection}`)

    this.populateModels(this.makeSelection, this.yearSelection)
    this.changeStep('model')
  }

  handleModelSelect = e => {
    e.preventDefault()
    const target = e.target
    const prevSelection = this.modelsStepTarget.querySelector('.selected')
    if (prevSelection) prevSelection.classList.remove('selected')
    target.classList.add('selected')

    this.modelSelection = target.getAttribute('data-value') || target.value
    if (!this.modelSelection) return
    const finalAnswer = `${this.yearSelection} ${this.makeSelection} ${this.modelSelection}`
    this.currentSelectionTargets.map( curr => curr.innerText = finalAnswer)

    this.submitButtonTarget.dataset.text = finalAnswer
    this.saveDataToStore()
    this.handleQuestionSubmit(this.submitButtonTarget)
  }

  populateMakes = (year = '') => {
    const context = this
    const url = `/api/v1/vehicles/makes?year=${year}&list_id=${this.listIdValue}`
    this.clearVariants(this.makesSelectTarget, this.makesButtonsTarget)
    fetch(url)
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        if (data.errors || data.length === 0) {
          console.log('CARS API ERROR:populateMakes', data.errors)
        } else {
          context.createOptionsFromData(data, context.makesSelectTarget, context.makesButtonsTarget, 'id', 'name')
        }
      })
  }

  populateModels = (makesId, year = '') => {
    const context = this
    const url = `/api/v1/vehicles/makes/${makesId}/models/?year=${year}&list_id=${this.listIdValue}`
    this.clearVariants(this.modelsSelectTarget, this.modelsButtonsTarget)
    fetch(url)
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        if (data.errors || data.length === 0) {
          console.log('CARS API ERROR:populateModels', data.errors)
        } else {
          context.createOptionsFromData(data, context.modelsSelectTarget, context.modelsButtonsTarget, 'id', 'name')
        }
      })
  }

  changeStep = (step) => {
    this.currentStep = step
    if (step === 'year') {
      this.makesStepTarget.classList.add(...this.hiddenClasses)
      this.yearsStepTarget.classList.remove(...this.hiddenClasses)
    }
    if (step === 'make') {
      this.yearsStepTarget.classList.add(...this.hiddenClasses)
      this.modelsStepTarget.classList.add(...this.hiddenClasses)
      this.makesStepTarget.classList.remove(...this.hiddenClasses)
    }
    if (step === 'model') {
      this.makesStepTarget.classList.add(...this.hiddenClasses)
      this.modelsStepTarget.classList.remove(...this.hiddenClasses)
    }
  }

  handleBackButton = e => {
    e.preventDefault()
    const prevStep = this.currentStep === 'model' ? 'make' : 'year'
    this.changeStep(prevStep)
  }

  createOptionsFromData = (data, selectEl, buttonsWrapper, valueParam, textParam) => {
    const MAX_BUTTONS = 12

    const createOption = item => {
      let option = document.createElement('option')
      option.value = item[valueParam]
      option.text = item[textParam]
      selectEl.appendChild(option)
    }

    const createButton = item => {
      const element = document.createElement('li')
      element.className = 'border-white rounded border-solid border cursor-pointer inline-block font-bold text-sm p-3 bg-gray-light text-center uppercase transition md:text-xl md:px-3 md:py-5 relative after:content-[""] after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:bg-transparent'
      element.innerText = item[textParam]
      element.dataset.value = item[valueParam]
      element.addEventListener('click', e => {
        if (buttonsWrapper === this.makesButtonsTarget) this.handleMakeSelect(e)
        if (buttonsWrapper === this.modelsButtonsTarget) this.handleModelSelect(e)
      })
      buttonsWrapper.appendChild(element)
    }

    selectEl.classList.add('hidden')
    data.map(function(item, idx) {
      const currNumber = idx + 1
      if (currNumber > MAX_BUTTONS) {
        selectEl.classList.remove('hidden')
        createOption(item) 
      } else {
        createButton(item)
      }
    })
  }

  clearVariants = (selectEl, buttonsEl) => {
    const clearOptions = selectEl => {
      const currentOptions = [...selectEl.options]
      currentOptions.map(o => {
        if (!o.classList.contains('select_placeholder')) o.remove()
      })
    }
    buttonsEl.innerHTML = '' //clear buttons
    clearOptions(selectEl)
  }


  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

}
