import ApplicationController from "@/controllers/application_controller"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"

export default class extends ApplicationController {
  trackerModule = new EventTrackerModule(ahoy)

  static values = {
    trialscopeSiteId: String,
    trialscopePolicyId: String,
    trialscopeText: String,
    trialscopeTitle: String,
  }

  initialize() {}
  connect() {}
  log() {}
  async handleRowClick(e) {
    this.trackRender()
  }

  trackRender() {
    const userData = JSON.parse(localStorage.getItem("savedUser") || "{}")
    const questionResults = JSON.parse(
      localStorage.getItem("questions_results") || "{}"
    )
    this.trackerModule.track("trialscope_listing_click", {
      site_id: this.trialscopeSiteIdValue,
      policy_id: this.trialscopePolicyIdValue,
      text: this.trialscopeTextValue,
      title: this.trialscopeTitleValue,
      user_data: userData,
      questions_results: questionResults,
    })
  }
}
