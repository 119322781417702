import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"

export default class extends ApplicationController {
  linkModule = new LinkModule()
  static values = {
    trialId: String,
    redirectUrl: String,
    externalId: String,
    isLoading: Boolean
  }
  static targets = ["result", "errorMessage", "clickableWrapper", "loader"]

  initialize() {
    this.nextinsureService = new NextinsureService("")
    this.publicErrorMessage = "Unfortunately, it looks like there are no offers available for you at this time."
  }
  connect() {
    this.performWallLoading()
  }

  async performWallLoading() {
    this.isLoadingValue = true
    this.clearErrors()
    const resp = await this.nextinsureService?.loadWall()
    console.log('respppp', resp)
    await this.renderWall(resp)
    this.isLoadingValue = false
  }

  async isLoadingValueChanged() {
    if (this.isLoadingValue) {
      this.loaderTarget.classList.remove("hidden")
    } else {
      this.loaderTarget.classList.add("hidden")
    }
  }

  clearErrors() {
    if (this.errorMessageTarget) this.errorMessageTarget.innerHTML = ""
  }

  async renderWall(api_response) {
    const wall = api_response
    console.log('WALL', wall)
    const renderErrors = (resp) => {
      console.log('resprenderERR', resp.errors)
      const errors = resp.errors || '[]'
      let message = ''
      errors.map(error => {
        message += `<div class="p-2 text-xs">${error.message}</div>`
      })
      return message
    }
    if (wall["error_message"]) {
      this.errorMessageTarget.innerHTML = `<div class="p-5" data-error="${wall["error_message"]}">${this.publicErrorMessage}</div>`
      this.errorMessageTarget.innerHTML += renderErrors(wall)
    } else {
      const offers = wall["result"]["listing"] || []
      console.log('OFFERS', offers)
      const offerListResponse = await fetch("/uikit/offer_list_markup", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": getMetaValue("csrf-token"),
        },
        body: JSON.stringify({
          offers: offers,
          wall_source: 'nextinsure',
        }),
      })
      const offerList = await offerListResponse.json()
      if (this.resultTarget) {
        this.resultTarget.innerHTML += offerList["result"]
      }
    }
  }


}


function getMetaValue(name) {
  const element = document?.head?.querySelector(`meta[name="${name}"]`)
  return element?.getAttribute("content")
}

class NextinsureService {
  constructor(token = '')  {
    this.submitLeadURL = `${window.location.origin}/api/v1/nextinsure/send_lead`
    this.internalLeadURL = `${window.location.origin}/leads`
  }

  async loadWall() {
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {})
    this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}")
    this.questionData = JSON.parse(
      localStorage.getItem("questions_results") || "{}"
    )
    const response = await fetch(this.submitLeadURL, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nextinsure: {
          client_id: "668893",
          client_ip:this.locationData.ip, 
          user_agent: navigator.userAgent,
          referrer: "path.seniorbenefitsworth.com",
          zipcode: this.questionData.zip
         }
      })
    }).then(function(res) {
      return res
    })
    console.log('RESPONSE::', response)
    let result = await response.json()
    console.log("RESULT::", result)
    result = result.data || result.errors || {}
    if (response.status === 200) {
      const internalLeadRes = this.saveExternalId(result["searchid"])
      console.log('internalLeadRes::', internalLeadRes)
      return { result: result }
    } else {
      console.log('ERR RESPONSE::', response)
      return {
        error_message: `Status: ${response.status} - Message: ${JSON.stringify(result["errors"])}`,
        errors: result["errors"]
      }
    }
  }

  async saveExternalId(externalId) {
    const response = await fetch(this.internalLeadURL, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lead: {
          external_storage_token: externalId,
        }
      })
    }).then(function(res) {
      return res
    })
    let result = await response.json()
    result = result.data || result.errors || {}
    console.log("saveExternalId:RESULT::", result)
    if (response.status === 200) {
      return { result: result }
    } else {
      console.log('saveExternalId:ERR RESPONSE::' + externalId, response)
      return {
        error_message: `Status: ${response.status} - Message: ${JSON.stringify(result["errors"])}`,
        errors: result["errors"]
      }
    }
  }

}
