import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"
import { ResultsStoreModule } from "@/modules/results_store_module"

export default class extends ApplicationController {
  static values = {
    step: Number,
    stepIndex: Number,
  }

  static targets = ["card"]
  linkModule = new LinkModule()

  initialize() {
    console.log( "[offer_list_component::initialize]")
  }

  connect() {
    this.stepValue = 1
    this.stepIndexValue = 0
    console.log( "[offer_list_component::connect]")
  }


  nextOffer(e) {
    console.log('NEXt_offer')
    if (this.stepValue >= this.cardTargets.length) {
      this.performFinalRedirect()
    } else {
      this.stepValue++
      this.stepIndexValue++
    }
  }


  stepValueChanged() {
    console.log('this.cardTargets', this.cardTargets)
    const prevItem = this.cardTargets[this.stepIndexValue-1]
    const newItem = this.cardTargets[this.stepIndexValue]
    if (newItem) {
      newItem.classList.remove("w-0", "h-0", "overflow-hidden")
      newItem.classList.add("opacity-100")
      if (prevItem) prevItem.classList.add("w-0", "h-0", "overflow-hidden")
      if (prevItem) prevItem.classList.remove("opacity-100")
    }

  }

  async performFinalRedirect() {
    const formUrlParams = (obj) =>
      "?" +
      Object.keys(obj)
        .map((key) => {
          return obj[key] ? key + "=" + obj[key] : ""
        })
        .join("&")
    let finalUrl = window.localStorage.getItem("savedRedirect")

    if (finalUrl) {
      this.userStoreModule = await ResultsStoreModule("savedUser")
      const userStoreObject = await this.userStoreModule.getStore("savedUser")
      finalUrl = this.linkModule.addCurrentParamsToUrl(finalUrl)
      finalUrl = new URL(this.linkModule.addParamsToUrl(finalUrl)(formUrlParams(userStoreObject)))
      if (finalUrl.origin == window.location.origin) {
        this.linkModule.performRedirect(finalUrl.toString())
      } else {
        this.linkModule.performRedirect(finalUrl.toString())
      }
    }
  }


}
