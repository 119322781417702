import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";

export default class extends ApplicationController {

  initialize(){
    console.log('Answer::InsuranceButtonsController[index]', this.element)
  }
  connect(){
    console.log('Answer::InsuranceButtonsController[connect]')
  }

  saveDataToStore = async (text) => {
    this.resultsStoreModule = await ResultsStoreModule()
    await this.resultsStoreModule.init()
    await this.resultsStoreModule.saveAnswer('insurance', text)
  }

  handleQuestionSubmit = (target) => {
    this.answerController.getAnswerData(target)
    this.answerController.handleAnswer()
  }

  handleInsuranceSelect = e => {
    e.preventDefault()
    const target = e.target
    target.classList.add('selected')
    
    const currentSelection = target.getAttribute('data-text') || target.value

    console.log('THIS CURRENT SELECTION', currentSelection)
    this.saveDataToStore(currentSelection)
    this.handleQuestionSubmit(target)
  }


  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

}
