import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    token: String,
  }
  initialize() {
    console.log("tiktok template init", this.tokenValue)
  }
  connect() {
    console.log("tiktok template connect", this.tokenValue)
  }
}
