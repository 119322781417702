import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ "barStep", "barLine", "barCircle"]

  initialize(){
    this.totalSteps
    this.currentStep
    console.log('[Chain::Progress::StepsComponent::initialize]', this.element)
  }

  connect(){
    console.log('[Chain::Progress::StepsComponent::connect]', true)
  }

  disconnect(){
    console.log('[Chain::Progress::StepsComponent::disconnect]', this.element)
  }

  updateStep(current, count) {
    this.currentStep = current
    this.totalSteps = count
    console.log('this.hasBarStepTarget', this.hasBarStepTarget)
    if (this.hasBarStepTarget) {
      const currentBarStepCount = this.barStepTargets.length;
  
      if (currentBarStepCount !== count) {
        if (currentBarStepCount < count) {
          const timesToCopy = count - currentBarStepCount;
          this.copyBarSteps(timesToCopy);
        } else {
          const timesToRemove = currentBarStepCount - count;
          this.removeBarSteps(timesToRemove);
        }
        //remove lineBar for the last Step adjust flex positioning
        this.barStepTargets.pop().classList.replace('flex-1', 'flex')
        this.barLineTargets.pop().remove()
      }

      this.colorActiveBarSteps(this.currentStep);
    }
  }


  copyBarSteps(timesToCopy) {
    console.log('TIMES TO COPY', timesToCopy)
    const originalBarStep = this.barStepTargets[0];
  
    for (let i = 0; i < timesToCopy; i++) {
      const clonedBarStep = originalBarStep.cloneNode(true);
      originalBarStep.parentNode.appendChild(clonedBarStep);
    }
  }
  
  removeBarSteps(timesToRemove) {
    const barStepParent = this.barStepTargets[0].parentNode;
  
    for (let i = 0; i < timesToRemove; i++) {
      const lastBarStep = this.barStepTargets[this.barStepTargets.length - 1];
      barStepParent.removeChild(lastBarStep);
    }
  }

  colorActiveBarSteps(current) {
    const activeColor = '#1d40b0';
    const barSteps = this.barStepTargets;
    console.log('currentCOLOR', barSteps)
    barSteps.map((step, index) => {
      console.log('INDE?CURE', [index, current])
      const isActiveStep = index < current;
      const backgroundColor = isActiveStep ? activeColor : '';
      const barLine = this.barLineTargets[index];
      if (barLine) {
        const lineItem = barLine.querySelector('.lineItem')
        lineItem.style.backgroundColor = backgroundColor;
      }

      const barCircle = this.barCircleTargets[index];
      if (barCircle) {
        const circleItems = [...barCircle.querySelectorAll('.circleItem')];
        barCircle.style.borderColor = backgroundColor;
        circleItems.map( (circleItem) => {
          circleItem.style.backgroundColor = backgroundColor;
          circleItem.classList.remove('animate-ping')
          if (circleItem && circleItem.classList.contains('outerCircle')) circleItem.classList.add('animate-ping')
        });
        if (current !== 1 && (index + 1) < current) {
          barCircle.style.backgroundColor = activeColor
          barCircle.querySelector('svg').classList.remove('!hidden')
          barCircle.querySelector('.itemsWrapper').classList.add('!hidden')
        }
      }
    });
  }
}