import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["input"]
  static values = {
    localStorageField: String,
  }

  async initialize() {}

  async connect() {
    await this.setLocalStorageToInputValue()
    window.addEventListener("storage", async (e) => {
      console.log("storage updated", e)
      await this.setLocalStorageToInputValue()
    })
  }

  async setLocalStorageToInputValue() {
    const localStorageValue = localStorage.getItem(this.localStorageFieldValue)
    await Promise.resolve().then(() => {
      if (localStorageValue) {
        this.inputTarget && this.inputTarget?.setAttribute("value", `${localStorageValue}`)
      } else {
        this.inputTarget && this.inputTarget?.setAttribute("value", "false")
      }
    })
  }
}
