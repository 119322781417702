import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  initialize() {}

  connect() {}

  handleAnswer(event) {
    event.preventDefault()
    this.answerController.getAnswerData(event.target)
    this.answerController.handleAnswer()
  }
}
