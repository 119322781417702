import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["openEndedSubmit", "openEndedInput"]

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  initialize() {}

  connect() {}

  handleOpenEndedInput(event) {
    const input = event.target
    this.openEndedSubmitTarget.dataset.text = input.value
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.openEndedInputTarget.reportValidity()) {
      this.answerController.getAnswerData(event.target)
      this.answerController.handleAnswer()
    }
  }
}
