import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["daySelect", "monthSelect", "yearSelect", "submitButton"]

  initialize(){
    console.log('Answer::DateSelectController[index]', this.element)
    this.day
    this.month
    this.year
  }
  connect(){
    console.log('Answer::DateSelectController[connect]')
  }

  handleSubmit(e) {
    e.preventDefault()
    let isValidDate = false
    let daysOnDate = 0
    const getDays = (year, month) => {
      return new Date(year, month, 0).getDate();
    }
    const handleAnswer = (target) => {
      this.answerController.getAnswerData(target)
      this.answerController.handleAnswer()
    }

    if (this.monthSelectTarget.reportValidity() && this.daySelectTarget.reportValidity() && this.yearSelectTarget.reportValidity()) {
      daysOnDate = getDays(this.year, this.month)
      isValidDate = this.day <= daysOnDate
    } else {
      return false
    }

    console.log('SUBMIT:', this.month + '-' + this.day + '-' + this.year)
    
    if (isValidDate) {
      e.target.dataset.text = `${this.month}-${this.day}-${this.year}`
      handleAnswer(e.target)
    } else {
      this.daySelectTarget.setCustomValidity('There is only ' + daysOnDate + ' days in this month')
      this.daySelectTarget.reportValidity()
    }
  }

  handleDayChange = e => {
    this.daySelectTarget.setCustomValidity('')
    this.day = e.target.value
  }

  handleMonthChange = e => {
    this.month = e.target.value
  }

  handleYearChange = e => {
    this.year = e.target.value
  }


  
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }
}
