import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    token: String
  }
  initialize(){
    console.log('freestar init template init', this.tokenValue)
  }
  connect(){
    console.log('freestar init connect', this.tokenValue)
  }
}
