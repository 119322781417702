import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    provider: String,
    token: String
  }
  initialize(){
    console.log('snippet manager init', this.providerValue, this.tokenValue)
  }
  connect(){
    console.log('snippet manager connect')
  }
}
