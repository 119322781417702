import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['content']
  initialize(){
    console.log('[offer_list::redfin::card::initialize]', true)

  }

  connect(){
    console.log('[offer_list::redfin::card::connect]', true)
    this.contentTarget.classList.add('!hidden')
  }

  toggleOpen = (e) => {
    e.preventDefault()
    this.contentTarget.classList.toggle('!hidden')
  }
}