import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"

export default class extends ApplicationController {
  linkModule = new LinkModule()
  static values = {
    campaignId: String,
    isLoading: Boolean
  }
  static targets = ["result", "errorMessage", "clickableWrapper", "loader"]

  initialize() {
    this.isLoadingValue = true
    this.tlyService = new TransparentlyService(this.campaignIdValue)
    this.publicErrorMessage = "Unfortunately, it looks like there are no offers available for you at this time."
  }
  connect() {
    this.performWallLoading()
    if (this.tlyService.error) {

      this.errorMessageTarget.innerHTML = `<div class="p-5" data-error="no campaign id">${this.publicErrorMessage}</div>`
    }
  }

  async performWallLoading() {
    this.clearErrors()
    const resp = await this.tlyService?.loadWall()
    console.log('resp::', resp)
    this.isLoadingValue = false
  }

  async isLoadingValueChanged() {
    if (this.isLoadingValue) {
      this.loaderTarget.classList.remove("hidden")
    } else {
      this.loaderTarget.classList.add("hidden")
    }
  }

  clearErrors() {
    if (this.errorMessageTarget) this.errorMessageTarget.innerHTML = ""
  }
}


class TransparentlyService {
  constructor(campaignId)  {
    this.campaignId = campaignId;
    console.log('this.campaignId::', this.campaignId)
    this.error = !this.campaignId
  }

  async loadWall() {
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {})
    // this.locationData = {"ip":"37.19.210.39","type":"ipv4","continent_code":"NA","continent_name":"North America","country_code":"US","country_name":"United States","region_code":"CO","region_name":"Colorado","city":"Denver","zip":"80264","latitude":39.742488861083984,"longitude":-104.98564147949219,"location":{"geoname_id":5419384,"capital":"Washington D.C.","languages":[{"code":"en","name":"English","native":"English"}],"country_flag":"https://assets.ipstack.com/flags/us.svg","country_flag_emoji":"🇺🇸","country_flag_emoji_unicode":"U+1F1FA U+1F1F8","calling_code":"1","is_eu":false},"time_zone":{"id":"America/Denver","current_time":"2022-06-30T11:36:55-06:00","gmt_offset":-21600,"code":"MDT","is_daylight_saving":true},"currency":{"code":"USD","name":"US Dollar","plural":"US dollars","symbol":"$","symbol_native":"$"},"connection":{"asn":212238,"isp":"Datacamp Limited"}}
    this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}")
    this.questionData = JSON.parse(
      localStorage.getItem("questions_results") || "{}"
    )
    this.params = new URLSearchParams(window.location.search)
    const data = {
      "state": this.locationData.region_code,
      "pubcampaignid": this.campaignId,
      "vertical":"2",
      "zipcode": this.locationData.zip,
      "vehiclecount": "1",
      "vehicleYear": this.questionData["carYear"],
      "make": this.questionData["carMake"],
      "model": this.questionData["carModel"],
      "currentlyinsured": this.getCurrentlyInsured(this.questionData["currentlyinsured"]),
      "currentInsuranceProvider": this.getInsuranceProvider(this.questionData["currentInsuranceProvider"]),
      "gender": this.getGender(this.questionData["gender"]),
      "dob":  this.getDob(this.questionData["birth"]),
      "maritalStatus": this.getMartialStatus(this.questionData["maritalStatus"]),
      "threeyearincidentcount": this.getThreeYearIncidentCount(this.questionData["threeyearincidentcount"]),
      "homeowner": this.getHomeOwner(this.questionData["homeowner"]),
      "ms1": this.params.get("sub1"),
      "ms2": this.params.get("sub4"),
      "FullName": `${this.userData.first_name} ${this.userData.last_name}`,
      "firstName": this.userData.first_name,
      "lastName": this.userData.last_name,
      "email": this.userData.email,
      "phone": this.userData.phone,
      "ConsumerIp": this.locationData.ip,
      "ConsumerUserAgent": navigator.userAgent,
      "devicetype": this.getDeviceType()
    }
    console.log('data::', data)
    return Transparently.Render(data);
  }

  getCurrentlyInsured = (storeAnswer) => {
    const insuredData = {
      "yes": "1",
      "no": "2"
    }
    return insuredData[storeAnswer.toLowerCase()]
  }
  getInsuranceProvider = (storeAnswer) => {
    let answerKey = false
    const insuredData = {
      "progressive": "1",
      "geico": "2",
      "state farm": "3",
      "allstate": "4",
      "nationwide": "5",
      "farmers insurance": "6",
      "liberty mutual": "7",
      "elephant insurance": "8",
      "mercury insurance": "9",
      "safe auto": "10",
      "metlife": "11",
      "infinity insurance": "12",
      "dairyland insurance": "13",
      "the general": "14",
      "21st century": "15",
      "direct general": "16",
      "other": "17",
      "esurance": "18",
      "travelers": "19",
      "usaa": "20",
    }
    if (storeAnswer) {
      answerKey = Object.keys(insuredData).find( name => storeAnswer.toLowerCase().includes(name))
    }
    return insuredData[answerKey] || insuredData['other']
  }
  getGender = (storeAnswer) => {
    const genderData = {
      "male": "1",
      "female": "2",
      "not": "3"
    }
    return genderData[storeAnswer.toLowerCase()] || "3"
  }
  getMartialStatus = (storeAnswer) => {
    const martialData = {
      "no": "1",
      "yes": "2",
      "divorced": "3"
    }
    return martialData[storeAnswer.toLowerCase()]
  }
  getHomeOwner = (storeAnswer) => {
    const homeOwnerData = {
      "yes": "1",
      "no": "2",
      "other": "3"
    }
    return homeOwnerData[storeAnswer.toLowerCase()]
  }
  getDob = (storeAnswer) => {
    const parseDate = (input, format) => {
      format = format || 'dd-mm-yyyy';
      // default format
      var parts = input.match(/(\d+)/g), 
          i = 0, fmt = {
          };
      // extract date-part indexes from the format
      format.replace(/(yyyy|dd|mm)/g, function(part) {
        fmt[part] = i++;
      }
                    );
      return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']], 12);
    }
    return parseDate(storeAnswer).toISOString().split('T')[0]
  }
  getThreeYearIncidentCount = (storeAnswer) => {
    const incidentData = {
      "no": "1",
      "yes": "2",
      "0": "1",
      "1": "2",
      "2": "3",
      "3": "4",
      "3+": "4",
    }
    return incidentData[storeAnswer.toLowerCase()]
  }
  getDui = (storeAnswer) => {
    const duiData = {
      "yes": "1",
      "no": "2"
    }
    return duiData[storeAnswer.toLowerCase()]
  }
  getDeviceType = () => {
    const ua = navigator.userAgent;
    const deviceTypeData = {
      "desktop": "1",
      "mobile": "2",
      "tablet": "3"
    }
    let type = 'desktop'
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      type =  "tablet"
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      type = "mobile"
    }
    return deviceTypeData[type]
  }

}
