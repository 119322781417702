import ApplicationController from "@/controllers/application_controller"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import { LinkModule } from "@/modules/link_module"
import { ResultsStoreModule } from "@/modules/results_store_module"
import ahoy from "ahoy.js"
import SparkMD5 from "spark-md5"

export default class extends ApplicationController {
  static values = { questionId: String, questionChainId: String, questionServicename: String }
  trackerModule = new EventTrackerModule(ahoy)
  linkModule = new LinkModule()

  initialize() {
    this.newTabRedirect
    this.redirect
    this.text
    this.answerId
    this.questionTrackingData
    this.parentQuestion = this.element.closest('.lander-item')
  }

  connect() {}

  disconnect() {}

  async handleAnswer() {
    await this.trackAnswer()
    this.questionController.triggerModal()
    this.questionController.triggerFloater()
    this.handleRedirect()
    const proceedToNextStep = !this.redirect || this.redirect.includes("tel:") || this.redirect.includes("mailto:")
    if (proceedToNextStep) this.chainController.nextStep()
  }

  getAnswerData(answer) {
    console.log("getAnswerData answer", answer)
    const answerData = answer.dataset
    const domain = window.location.host
    this.questionTrackingData = this.questionController.trackingData()
    this.newTabRedirect = answerData?.newTabRedirect
    this.redirect = answerData?.redirect
    this.text = answerData?.text
    this.id = answerData?.id
    const uniqSeed = this.text || this.id

    this.trackingAnswerId = SparkMD5.hash(this.questionTrackingData.tracking_question_id + uniqSeed + domain)
  }

  handleRedirect() {
    const newTabRedirect = this.newTabRedirect === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.newTabRedirect
    const redirect = this.redirect === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.redirect
    if (newTabRedirect)
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(newTabRedirect),
        true
      )
    if (redirect) {
      if (redirect.includes("tel:") || redirect.includes("mailto:")) {
        window.location.href = redirect;
      } else {
        this.linkModule.performRedirect(
          this.linkModule.addCurrentParamsToUrl(redirect)
        )
      }
    }
      

  }

  async trackAnswer() {
    this.resultsStoreModule = await ResultsStoreModule()
    await this.resultsStoreModule.init()
    await this.resultsStoreModule.saveAnswer(this.questionIdValue, this.text)

    console.log('THIS.SERVICENAME_VALUE::', this.questionServicenameValue)
    if (this.questionServicenameValue) await this.resultsStoreModule.saveAnswer(this.questionServicenameValue, this.text)
    this.trackerModule.track("answer_click", {
      ...this.questionTrackingData,
      tracking_answer_id: this.trackingAnswerId,
      tracking_answer: this.text,
      question_id: this.questionIdValue,
      question_chain_id: this.questionChainIdValue,
      answer_id: this.id,
      answer_text: this.text,
      page: window.location.pathname,
      url: window.location.href,
    })
  }

  get chainController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(".chain"),
      "chain--component"
    )
  }

  get questionController() {
    return this.application.getControllerForElementAndIdentifier(
      this.parentQuestion,
      "chain--question--component"
    )
  }
}
