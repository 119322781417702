import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";

export default class extends ApplicationController {
  static targets = ["select", "buttonsWrapper"]

  initialize(){
    console.log('Answer::SolarButtonsController[index]', this.element)
    this.userData = JSON.parse(localStorage.getItem('savedUser') || '{}')
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {})
    this.populateByState()
  }
  connect(){
    console.log('Answer::SolarButtonsController[connect]', this.userData.state || this.locationData.region_code)
  }

  handleQuestionSubmit = (target) => {
    this.answerController.getAnswerData(target)
    this.answerController.handleAnswer()
  }

  getStoreData = () => {
    this.userData = JSON.parse(localStorage.getItem('savedUser') || '{}')
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {})
  }

  populateByState = () => {
    this.getStoreData()
    const context = this
    const state = this.userData.state || this.locationData.region_code

    const url = `/api/v1/agencies/?state=${state}`
    this.clearVariants(this.selectTarget, this.buttonsWrapperTarget)
    fetch(url)
      .then(function(response) {
        return response.json()
      })
      .then(function({data}) {
        if (data.errors || data.length === 0) {
          console.log('AGENCY API ERROR:populateByState', data.errors)
        } else {
          context.createOptionsFromData(data, context.selectTarget, context.buttonsWrapperTarget, 'id', 'name')
        }
      })
  }



  handleSelect = e => {
    e.preventDefault()
    const target = e.target
    target.classList.add('selected')
    
    const currentSelection = target.getAttribute('data-text') || target.value

    console.log('THIS CURRENT SELECTION', currentSelection)
    this.handleQuestionSubmit(target)
  }

  handleDropdownSelect = e => {
    e.preventDefault()
    console.log('Answer::SolarButtons::ComponentController#handleDropdownSelect]', e)
    const options = e.target.options
    const answer = options[options.selectedIndex]
    this.handleQuestionSubmit(answer)
  }

  createOptionsFromData = (data, selectEl, buttonsWrapper, valueParam, textParam) => {

    const createOption = item => {
      let option = document.createElement('option')
      option.value = item[valueParam]
      option.text = item[textParam]
      option.dataset.text = item[textParam]
      selectEl.appendChild(option)
    }

    const createButton = item => {
      const element = document.createElement('li')
      element.className = "border-white rounded border-solid border cursor-pointer inline-block font-bold text-sm text-[#1c6ea5] p-3 bg-gray-light text-center capitalize transition md:text-lg md:px-3 md:py-5 relative after:content-['']  after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:bg-transparent"
      element.innerHTML = `<span class="block text-ellipsis overflow-hidden whitespace-nowrap">${item[textParam]}</span>`
      element.dataset.text = item[valueParam]
      element.dataset.action = 'click->chain--answer--solar-buttons--component#handleSelect'
      buttonsWrapper.appendChild(element)
    }
    this.clearVariants(this.selectTarget, this.buttonsWrapperTarget)
    selectEl.classList.add('hidden')
    data.map( (item) => {
      if (item.isButton === false) {
        selectEl.classList.remove('hidden')
        createOption(item) 
      } else {
        createButton(item)
      }
    })
  }

  clearVariants = (selectEl, buttonsEl) => {
    const clearOptions = selectEl => {
      const currentOptions = [...selectEl.options]
      currentOptions.map(o => {
        if (!o.classList.contains('select_placeholder')) o.remove()
      })
    }
    buttonsEl.innerHTML = '' //clear buttons
    clearOptions(selectEl)
  }

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

}
