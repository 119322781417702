import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    token: String,
  }
  initialize() {
    console.log("fb_ga_ef_to_sub_five snippet init", this.tokenValue)
  }
  connect() {
    console.log("fb_ga_ef_to_sub_five sniipet init", this.tokenValue)
  }
}
