import ApplicationController from "@/controllers/application_controller"
import { ResultsStoreModule } from "@/modules/results_store_module"

export default class extends ApplicationController {
  static targets = ["input", "map"]

  get validatorController() {
    return this.application.getControllerForElementAndIdentifier(this.inputTarget, "field-validation")
  }

  initialize() {
    this.service = new google.maps.places.AutocompleteService()
    this.detailsService = new google.maps.places.PlacesService(this.mapTarget)
    this.sessionToken = false
    console.log("[LeadForms::Field::Zipcode::Component::initialize]", this.detailsService)
  }

  connect() {}

  handlePrediction = async (predictions, status) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      console.error("GOOGLE PLACES SERVICE", status)
      this.validatorController.addErrorState()
      return
    }
    if (predictions.length > 0) {
      const firstPrediction = predictions[0]
      const placeId = firstPrediction.place_id
      await this.getStateByPlaceId(placeId)
      this.refreshSessionToken()
    }
  }

  getStateByPlaceId = async (placeId) => {
    const saveToUserStore = async (key, text) => {
      this.userStoreModule = await ResultsStoreModule("savedUser")
      await this.userStoreModule.init()
      await this.userStoreModule.saveAnswer(key, text)
    }
    const request = {
      placeId: placeId,
      fields: ["address_component"],
    }
    const saveStateToUserStore = async (place, _status) => {
      console.log("[LeadForms::Field::Zipcode::Component::getStateByPlaceId]", place)
      const stateData = place["address_components"].find((i) => i.types.includes("administrative_area_level_1"))
      const cityData = place["address_components"].find((i) => i.types.includes("locality"))
      const shortState = stateData["short_name"]
      const city = cityData["long_name"]
      saveToUserStore("state", shortState)
      saveToUserStore("city", city)
      this.refreshAgencies()
      return stateData
    }
    await this.detailsService.getDetails(request, saveStateToUserStore)
  }

  refreshAgencies = async () => {
    const agenciesElements = [
      ...document.querySelectorAll(
        '[data-controller="chain--answer--solar-buttons--component chain--answer--component"]'
      ),
    ]
    agenciesElements.map((el) => {
      const controller = this.application.getControllerForElementAndIdentifier(
        el,
        "chain--answer--solar-buttons--component"
      )
      controller.populateByState()
    })
  }

  refreshSessionToken = () => {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken()
  }
}
