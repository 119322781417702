export class ChainParamConditionModule {
  constructor(conditions, params, nodes) {
    this.conditions = conditions
    this.params = params
    this.nodes = nodes
    this.nodesWrapper = nodes[0].parentElement
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {})
  }

  async checkConditions() {
    const items = await this.collectConditionItems(this.conditions, this.params)
    const deleteConditions = items.filter((cond) => cond.hide === true)
    const moveConditions = items
      .filter((cond) => cond.hide !== true && cond.position)
      .sort((a, b) => a.position - b.position)
    deleteConditions.map((cond) => {
      const condItem = this.nodes.find((i) => i.dataset.id === cond.id)
      this.nodes = this.nodes.filter((i) => i.dataset.id !== cond.id)
      condItem.remove()
    })
    this.nodes = this.recalculatePositions(this.nodes)
    moveConditions.map((cond) => this.handleChangePosition(this.nodes, cond))
    this.nodes.sort((a, b) => a.dataset.position - b.dataset.position).forEach((e) => this.nodesWrapper.appendChild(e))
  }

  recalculatePositions(arr) {
    return arr.map((item, idx) => {
      item.dataset.position = idx + 1
      return item
    })
  }

  handleChangePosition(arr, cond) {
    const element = arr.find((i) => i.dataset.id === cond.id)
    if (!element) return arr
    const newPos = cond.position
    arr = arr.map((i) => {
      if (element.dataset.position < newPos) {
        if (i.dataset.position > element.dataset.position && i.dataset.position <= newPos) i.dataset.position -= 1
      } else if (element.dataset.position > newPos) {
        if (i.dataset.position < element.dataset.position && i.dataset.position >= newPos) i.dataset.position += 1
      }
      return i
    })
    element.dataset.position = newPos
    return arr
  }

  async collectConditionItems(conditions, params) {
    function mapAsync(array, callbackfn) {
      return Promise.all(array.map(callbackfn))
    }

    function filterAsync(array, callbackfn) {
      return mapAsync(array, callbackfn).then((filterMap) => {
        return array.filter((value, index) => filterMap[index])
      })
    }
    const removeDuplicatesByKey = (collection, keys) => {
      return collection.filter((item, idx, arr) => {
        return (
          idx ===
          arr.findIndex((el) => {
            const isDuplicate = keys.reduce((acc, key) => {
              acc &&= el[key] === item[key]
              return acc
            }, true)
            return isDuplicate
          })
        )
      })
    }
    let result_items = []
    const isMatchedCondition = async (cond) => {
      return await this.conditionMatched(cond, params)
    }
    const matched = await filterAsync(conditions, isMatchedCondition)
    result_items = await matched.map((cond) => cond.items)
    return await removeDuplicatesByKey(result_items.flat(), ["id", "hide"])
  }

  async conditionMatched(condition, params) {
    let matched = false
    if (condition.checkType === "zip") {
      matched = await this.checkZip(condition.zipListId, this.locationData.zip)
    } else {
      matched = params[condition.name]
      if (condition.value) matched = params[condition.name] === condition.value
    }
    return matched
  }

  async checkZip(zipListId, clientZip) {
    const url = `/api/v1/zip_lists/${zipListId}/check?zip=${clientZip}`
    return await fetch(url)
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        return data.valid
      })
  }
}
