import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"
import Glide from "@glidejs/glide";

export default class extends ApplicationController {
  static values = {
    step: Number,
    stepIndex: Number,
  }

  static targets = ["card"]
  linkModule = new LinkModule()

  initialize() {
    console.log('GLIDE INIT', Glide)
    console.log( "[offer_list::redfin::slider::initialize]")
  }

  connect() {
    console.log( "[offer_list::redfin::slider::connect]")
    this.initializeGlide();
  }

  initializeGlide() {
    const glideElement = this.element.querySelector(".glide");
    if (glideElement) {
      let breakpoints = {
        1400: {
          perView: 4,
        },
        1024: {
          perView: 3,
        },
        600: {
          perView: 1,
        },
      };

      const TranslateTransformer = (Glide, Components) => {
        return {
          modify: function (_translate) {
            const gap = Components.Gaps.value;
            const slideWidth = 282;
            const currentIndex = Glide.index;
            return (slideWidth + gap) * currentIndex;
          },
        };
      };

      const glide = new Glide(glideElement, {
        type: "slider",
        startAt: 0,
        perView: 1,
        gap: 30,
        focusAt: 0,
        bound: false,
        breakpoints: breakpoints,
      });
      glide.mutate([TranslateTransformer]).mount();
    }
  }

}
