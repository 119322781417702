import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"

export default class extends ApplicationController {
  static values = {
    trialId: String,
    redirectUrl: String,
    externalId: String,
    pollCount: Number,
    isLoading: Boolean,
  }
  static targets = ["result", "errorMessage", "clickableWrapper", "loader"]
  linkModule = new LinkModule()

  initialize() {
    this.pollCount = 0
    this.evenfinancialService = new EvenfinancialService("")
    this.publicErrorMessage = "Unfortunately, it looks like there are no offers available for you at this time."
  }
  connect() {
    if (this.redirectUrlValue) {
      window.localStorage.setItem("savedRedirect", this.redirectUrlValue)
    }
    this.performWallLoading()
  }

  async pollCountValueChanged() {
    this.isLoadingValue = true
    if (!this.externalIdValue) return
    const resp = await this.evenfinancialService?.pollWall(this.externalIdValue)
    console.log(`pollCountValueChanged::[${new Date().getMinutes()}:${new Date().getSeconds()}]`, resp)
    await this.renderWall(resp)
    this.isLoadingValue = false
  }

  async performWallLoading() {
    this.isLoadingValue = true
    this.clearErrors()
    const resp = await this.evenfinancialService?.loadWall()
    console.log("respppp", resp)
    await this.renderWall(resp)
    this.isLoadingValue = false
  }

  async isLoadingValueChanged() {
    if (this.isLoadingValue) {
      this.loaderTarget.classList.remove("hidden")
    } else {
      this.loaderTarget.classList.add("hidden")
    }
  }

  clearErrors() {
    if (this.errorMessageTarget) this.errorMessageTarget.innerHTML = ""
  }

  async renderWall(api_response) {
    const wall = api_response
    console.log("WALL", wall)
    const renderErrors = (resp) => {
      console.log("resprenderERR", resp.errors)
      const errors = JSON.parse(resp.errors || "[]")
      let message = ""
      errors.map((error) => {
        message += `<div class="p-2 text-xs">${error.message}</div>`
      })
      return message
    }
    if (wall["error_message"]) {
      this.errorMessageTarget.innerHTML = `<div class="p-5" data-error="${wall["error_message"]}">${this.publicErrorMessage}</div>`
      this.errorMessageTarget.innerHTML += renderErrors(wall)
    } else {
      this.externalIdValue = wall["result"]["uuid"]
      if (wall["result"]["pendingResponses"].length > 0) {
        const pollDelay = wall["result"]["loanOffers"].length == 0 ? 0 : 15000
        setTimeout(() => {
          this.pollCountValue += 1
        }, pollDelay)
      }
      const offers = mergeOffers(wall["result"]["specialOffers"], wall["result"]["loanOffers"])
      console.log("OFFERS", offers)
      const offerListResponse = await fetch("/uikit/offer_list_markup", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": getMetaValue("csrf-token"),
        },
        body: JSON.stringify({
          offers: offers,
          externalId: `${this.externalIdValue}`,
          wall_source: "evenfinancial",
        }),
      })
      const offerList = await offerListResponse.json()
      if (this.resultTarget) {
        this.resultTarget.innerHTML += offerList["result"]
      }
    }
  }
}

function mergeOffers(specialOffers, loanOffers) {
  let offers = []

  const parseSpecialOffer = (specialOffer) => {
    
    return {
      title: specialOffer.name,
      text: specialOffer.desc,
      redirect_url: specialOffer.url,
      partner_image_url: specialOffer.partnerImageUrl,
      partner_name: specialOffer.partnerName,
      offer_id: specialOffer.uuid,
      pre_approved: specialOffer.preApproved,
      pre_qualified: specialOffer.preQualified,
      sub_type: specialOffer.productSubType,
    }
  }

  const parseLoanOffer = (loanOffer) => {
    return {
      redirect_url: loanOffer.url,
      partner_image_url: loanOffer.originator.images[0].url,
      partner_name: loanOffer.originator.name,
      offer_id: loanOffer.uuid,
      pre_approved: loanOffer.preApproved,
      pre_qualified: loanOffer.preQualified,
      secured: loanOffer.secured,
      sub_type: loanOffer.productSubType,
      max_amount: loanOffer.maxAmount,
      description: loanOffer.originator.description,
      disclaimer: loanOffer.originator.disclaimer,
      sub_type_disclaimer: loanOffer.productSubTypeDisclaimer,
      description_items: [
        {
          title: `${loanOffer.termLength} ${loanOffer.termUnit}`,
          text: loanOffer.termDescription || 'Term of Loan',
        },
        {
          title: `${loanOffer.maxApr}%`,
          text: loanOffer.aprDescription || loanOffer.aprType + ' APR',
        },
        {
          title: `$${loanOffer.maxMonthlyPayment}`,
          text: loanOffer.monthlyPaymentDescription || 'Est. Monthly Payment',
        },
      ],
    }
  }

  loanOffers.map((loanOffer) => offers.push(parseLoanOffer(loanOffer)))
  specialOffers.map((specialOffer) => offers.push(parseSpecialOffer(specialOffer)))

  return offers.sort((a,b) => a.payout - b.payout || a.recommendationScore - b.recommendationScore);
}

function getMetaValue(name) {
  const element = document?.head?.querySelector(`meta[name="${name}"]`)
  return element?.getAttribute("content")
}

class EvenfinancialService {
  constructor(token = "") {
    this.submitLeadURL = `${window.location.origin}/api/v1/evenfinancial/send_lead`
    this.getOfferURL = `${window.location.origin}/api/v1/evenfinancial/get_offers/`
    this.internalLeadURL = `${window.location.origin}/leads`
  }

  async loadWall() {
    this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}")
    this.questionData = JSON.parse(localStorage.getItem("questions_results") || "{}")
    const response = await fetch(this.submitLeadURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        evenfinancial: {
          email: this.userData.email,
          phone: this.userData.phone,
          address: this.questionData.address,
          first_name: this.userData.first_name,
          last_name: this.userData.last_name,
          state: this.userData.state,
          city: this.userData.city,
          zipcode: this.questionData.zip,
          ssn: this.questionData.social,
          loan_purpose: this.questionData.loan_purpose || 'debt consolidation',
          loan_amount: this.questionData.loan_amount,
          property_status: this.questionData.property_status,
          credit_rating: this.questionData.credit_rating,
          employment_status: this.questionData.employment_status,
          employment_pay_frequency: this.questionData.employment_pay_frequency,
          annual_income: this.questionData.annual_income,
          education_level: this.questionData.education_level,
          date_of_birth: this.questionData.date_of_birth,
        },
      }),
    }).then(function (res) {
      return res
    })
    console.log("RESPONSE::", response)
    let result = await response.json()
    console.log("RESULT::", result)
    result = result.data || result.errors || {}
    if (response.status === 200) {
      const internalLeadRes = this.saveExternalId(result.leadUuid)
      console.log("internalLeadRes::", internalLeadRes)
      return { result: result }
    } else {
      console.log("ERR RESPONSE::", response)
      return {
        error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
        errors: result["errors"],
      }
    }
  }

  async saveExternalId(externalId) {
    const response = await fetch(this.internalLeadURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lead: {
          external_storage_token: externalId,
        },
      }),
    }).then(function (res) {
      return res
    })
    let result = await response.json()
    result = result.data || result.errors || {}
    console.log("saveExternalId:RESULT::", result)
    if ([201, 200].includes(response.status)) {
      return { result: result }
    } else {
      console.log("saveExternalId:ERR RESPONSE::" + externalId, response)
      return {
        error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
        errors: result["errors"],
      }
    }
  }

  async pollWall(uuid) {
    const response = await fetch(this.getOfferURL + uuid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      return res
    })
    console.log("RESPONSE::", response)
    let result = await response.json()
    console.log("RESULT::", result)
    result = result.data || result.errors || {}
    if (response.status === 200) {
      return { result: result }
    } else {
      console.log("ERR RESPONSE::", response)
      return {
        error_message: `Status: ${response.status} - Message: ${result["errors"]}`,
        errors: result["errors"],
      }
    }
  }
}
