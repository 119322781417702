import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"

export default class extends ApplicationController {
  linkModule = new LinkModule()
  static values = {
    trialId: String,
    redirectUrl: String,
  }
  static targets = ["result", "errorMessage", "clickableWrapper"]
  initialize() {
    const trialId =
      this.hasTrialIdValue && this.trialIdValue.length > 0
        ? this.trialIdValue
        : "NCT05085366"
    console.log("@@@@#!#!#!#", trialId)
    this.zipService = new ZipService()
    this.trialscopeService = new TrialscopeService(
      trialId,
      "48e68df0989ad4cfa0ede1aa84ee0559"
    )
    this.publicErrorMessage = "sorry not available for now"
  }
  connect() {
    this.performWallLoading()
    window.addEventListener("storage", (e) => {
      console.log("storage updated", e)
      this.performWallLoading()
    })
    
  }

  log() {
    console.log("trialscopeWall log")
  }
  goToRedirectUrl(e) {
    console.log("goToRedirectUrl url", this.redirectUrlValue)
    if (this.redirectUrlValue) {
      this.linkModule.performRedirect(this.redirectUrlValue)
    } else {
      if (this.errorMessageTarget)
        this.errorMessageTarget.innerHTML = `<div>${this.publicErrorMessage}</div>`
    }
  }
  performWallLoading() {
    const zip = this.getZipFromLocalStorage()
    this.clearErrors()
    if (!zip) this.handleNoZip()
    if (zip) this.renderWall(zip)
  }
  getZipFromLocalStorage() {
    this.userData = JSON.parse(localStorage.getItem("savedUser") || "{}")
    this.questionResults = JSON.parse(
      localStorage.getItem("questions_results") || "{}"
    )
    return this.userData["zip"] || this.questionResults["zip"]
  }
  clearErrors() {
    if (this.errorMessageTarget) this.errorMessageTarget.innerHTML = ""
  }

  handleNoZip() {
    if (this.errorMessageTarget)
      this.errorMessageTarget.innerHTML = `<div data-error="No ZIP">${this.publicErrorMessage}</div>`
  }
  async renderWall(zip) {
    const {
      lat: lat,
      long: long,
      error_message: errorMessage,
    } = await this.zipService.toLatLong(zip)
    if (errorMessage) {
      this.errorMessageTarget.innerHTML = `<div data-error="${errorMessage}">${this.publicErrorMessage}</div>`
      if (this.resultTarget) {
        this.resultTarget.innerHTML = ""
      }
    }
    if (!errorMessage) {
      const wall = await this.trialscopeService?.loadWall(lat, long)
      if (wall["error_message"]) {
        this.errorMessageTarget.innerHTML = `<div data-error="${errorMessage}">${this.publicErrorMessage}</div>`
        if (this.resultTarget) {
          this.resultTarget.innerHTML = ""
        }
      } else {
        const offerListResponse = await fetch("/uikit/offer_list_markup", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": getMetaValue("csrf-token"),
          },
          body: JSON.stringify({
            offers: wall["result"],
            redirect_url: `${this.redirectUrlValue}`,
            trial_id: `${this.trialIdValue}`,
            wall_source: 'trialscope'
          }),
        })
        const offerList = await offerListResponse.json()
        if (this.resultTarget) {
          this.resultTarget.innerHTML = offerList["result"]
        }
      }
    }

    console.log("error_message", errorMessage)
  }
}

function getMetaValue(name) {
  const element = document?.head?.querySelector(`meta[name="${name}"]`)
  return element?.getAttribute("content")
}

class ZipService {
  API_URL = "https://maps.googleapis.com/maps/api/geocode/json"
  API_KEY = "AIzaSyC8RitsVHn7rSDAoQxr-oVtV-EocnETXL4"
  async toLatLong(zip) {
    try {
      if (!zip) return { error_message: "no zip provided" }
      const response = await fetch(
        `${this.API_URL}?address=${zip}&key=${this.API_KEY}`
      )
      const result = await response.json()
      if (result.status === "ZERO_RESULTS") {
        return { error_message: result["status"] }
      }
      if (result.status === "OK") {
        if (result["results"] && result["results"].length > 0) {
          const location =
            result["results"][0]["geometry"] &&
            result["results"][0]["geometry"]["location"]
          if (location) return { lat: location["lat"], long: location["lng"] }
        } else {
          return { error_message: result["status"] }
        }
      }
      return { error_message: result["error_message"] || result["error"] }
    } catch (e) {
      return { error_message: e.message }
    }
  }
}

class TrialscopeService {
  constructor(trailId, token) {
    this.wallURL = `https://connect-staging.trialscope.com/api/v1/trials/${trailId}/sites`
    this.token = token
  }

  async loadWall(lat, long) {
    const response = await fetch(`${this.wallURL}?lat=${lat}&long=${long}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}"`,
      },
    })
    const result = await response.text()
    console.log("12312312312", result)
    console.log("12312312312", result.status)
    if (response.status === 200) {
      return { result: JSON.parse(result) }
    } else {
      return {
        error_message: `Status: ${response.status} - Message: ${result["error"]}`,
      }
    }
  }
}
