import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [ "option", "checkmark" ]
  static classes = [ "hidden" ] 

  initialize(){
    console.log('Answer::Checkbox::Question::Controller[index]', this.element)
    this.currentSelection = this.element.querySelector('input[checked]')
    this.parentQuestion = this.element.closest('[data-controller="chain--answer--checkbox--component chain--answer--component"]')
    this.haveSiblings = this.parentQuestion.querySelectorAll('[data-chain--answer--checkbox--component-target="question"]').length > 1
  }
  connect(){
    console.log('Answer::Checkbox::Question::Controller[connect]')
  }

  selectCheckbox(event) {
    const target = event.target
    this.currentSelection = target
    this.checkmarkTargets.map( t => {
      t.classList.add(this.hiddenClass)
    })
    const parentEl = target.closest('label')
    console.log('paretnEl', parentEl)
    const checkmarkTarget = parentEl.querySelector('svg')
    console.log('checkmarkTarget', checkmarkTarget)
    checkmarkTarget.classList.remove(this.hiddenClass)
    if (!this.haveSiblings) this.parentQuestionController.handleSubmit() // submit on change if only one checkbox questions in the group
  }

  async handleAnswer() {
    console.log('Answer::Checkbox::Question::Controller', this.currentSelection)
    this.answerController.getAnswerData(this.currentSelection)
    return await this.answerController.trackAnswer()
  }

  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component")
  }

  get parentQuestionController() {
    return this.application.getControllerForElementAndIdentifier(this.parentQuestion, "chain--answer--checkbox--component")
  }

}
