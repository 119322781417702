import ApplicationController from "@/controllers/application_controller"
import { ResultsStoreModule } from "@/modules/results_store_module"

export default class extends ApplicationController {
  static targets = ["input", "results", "map"]
  AC_THRESHOLD = 3

  initialize() {
    this.service = new google.maps.places.AutocompleteService()
    this.detailsService = new google.maps.places.PlacesService(this.mapTarget)
    this.sessionToken = false
  }

  connect() {}

  handleAddressChange = (e) => {
    const target = e.target
    const val = target.value
    const container = this.resultsTarget
    if (val.length > this.AC_THRESHOLD) {
      if (!this.sessionToken) this.refreshSessionToken()
      this.service.getPlacePredictions(
        { input: val, sessionToken: this.sessionToken, componentRestrictions: { country: ["us"] } },
        (items, status) => this.displaySuggestions(items, status, container)
      )
    } else {
      const acList = this.resultsTarget
      acList.classList.add("hidden")
    }
  }

  displaySuggestions = (predictions, status, container) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
      console.error("GOOGLE PLACES SERVICE", status)
      return
    }
    const autocompleteList = this.resultsTarget
    autocompleteList.innerHTML = ""
    autocompleteList.classList.remove("hidden")
    console.log("PREDICTION", predictions)
    predictions.map((prediction) => {
      const li = document.createElement("li")
      li.classList.add(
        "cursor-pointer",
        "px-4",
        "py-1",
        "text-sm",
        "text-left",
        "text-gray-base",
        "hover:bg-gray-light"
      )
      li.onclick = this.handleItemClick
      li.dataset.id = prediction.place_id
      li.appendChild(document.createTextNode(prediction.description))
      autocompleteList.appendChild(li)
    })
  }

  handleItemClick = async (e) => {
    const target = e.target
    const input = this.inputTarget
    const autocompleteList = this.resultsTarget
    input.value = target.innerText
    autocompleteList.classList.add("hidden")
    await this.getStateByPlaceId(target.dataset.id)
    this.refreshSessionToken()
  }

  getStateByPlaceId = async (placeId) => {
    const saveToUserStore = async (key, text) => {
      this.userStoreModule = await ResultsStoreModule("savedUser")
      await this.userStoreModule.init()
      await this.userStoreModule.saveAnswer(key, text)
    }
    const request = {
      placeId: placeId,
      fields: ["address_component"],
    }
    const saveStateToUserStore = async (place, _status) => {
      console.log(':::place', place)
      const stateData = place["address_components"].find((i) => i.types.includes("administrative_area_level_1"))
      const cityData = place["address_components"].find((i) => i.types.includes("locality") || i.types.includes("administrative_area_level_1") )
      const zipData = place["address_components"].find((i) => i.types.includes("postal_code"))
      const shortState = stateData["short_name"]
      const city = cityData["long_name"]
      const zip = zipData["long_name"]
      saveToUserStore("state", shortState)
      saveToUserStore("city", city)
      saveToUserStore("zip", zip)
      return stateData
    }
    this.detailsService.getDetails(request, saveStateToUserStore)
  }

  refreshSessionToken = () => {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken()
  }
}
