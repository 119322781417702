import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    token: String
  }
  initialize(){
    console.log('google analytics template init', this.tokenValue)
  }
  connect(){
    console.log('google analytics connect', this.tokenValue)
  }
}
