import ApplicationController from "@/controllers/application_controller"
import Rails from "@rails/ujs"
import { LinkModule } from "@/modules/link_module"
import { LeaptheoryModule } from "@/modules/integrations/leaptheory_module"
import { ResultsStoreModule } from "@/modules/results_store_module"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import { MoneylionMarketplaceModule } from "@/modules/integrations/moneylion_marketplace_module"
import ahoy from "ahoy.js"
import tippy from "tippy.js"
import SparkMD5 from "spark-md5"
import "tippy.js/dist/tippy.css"

const linkModuleInst = new LinkModule()

function setCookie(cName, cValue, expDays) {
  let date = new Date()
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + date.toUTCString()
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/"
}

export default class extends ApplicationController {
  static targets = ["errorMessage", "form", "chain", "field", "submitButton", "submitLoader"]
  static values = {
    id: String,
    sfCreds: String,
    submitRedirectUrl: String,
    submitPopupUrl: String,
    servicename: String,
  }
  linkModule = linkModuleInst
  trackerModule = new EventTrackerModule(ahoy)

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(document.body, "modal")
  }

  initialize() {
    const domain = window.location.host
    this.parentModal = this.element.closest("[data-modal]")
    this.parentQuestionController
    this.questionTrackingData = {}

    const extractFieldNameFromParamsWrapper = (fieldName) => {
      const startOfWrapper = fieldName?.lastIndexOf("[")
      const endOfWrapper = fieldName?.lastIndexOf("]")
      return fieldName?.slice(startOfWrapper + 1, endOfWrapper - 1)
    }
    const fillInputsFromQueryParams = () => {
      const urlParams = new URLSearchParams(window.location.search)
      if (this.hasFieldTarget) {
        this.fieldTargets.map((field) => {
          const fieldName = extractFieldNameFromParamsWrapper(field?.getAttribute("name"))
          const fieldData = field.dataset
          const fieldParam = fieldData.param ? fieldData.param : fieldName
          const paramValue = urlParams.get(fieldParam)
          if (paramValue) field.value = paramValue
        })
      }
    }
    fillInputsFromQueryParams()
    this.getQuestionController()
    this.trackingFormFields = this.fieldTargets.map( field => extractFieldNameFromParamsWrapper(field?.getAttribute("name")))
    this.trackingLeadFormId = SparkMD5.hash(this.trackingFormFields + domain)
  }

  connect() {
    this.trackRender()
  }

  trackRender() {
    this.trackerModule.track("lead_form_render", {
      ...this.questionTrackingData,
      tracking_form_id: this.trackingLeadFormId,
      tracking_form_fields: this.trackingFormFields,
      lead_form_id: this.idValue,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href,
    })
  }

  getFormJSON(form) {
    const data = new FormData(form)
    return Array.from(data.keys()).reduce((result, key) => {
      result[key] = data.get(key)
      return result
    }, {})
  } 

  async trackSubmit(form) {
    const saveToQuestionsStore = async (key, text) => {
      this.resultsStoreModule = await ResultsStoreModule()
      await this.resultsStoreModule.init()
      await this.resultsStoreModule.saveAnswer(key, text)
    }
    const saveToUserStore = async (key, text) => {
      this.userStoreModule = await ResultsStoreModule("savedUser")
      await this.userStoreModule.init()
      await this.userStoreModule.saveAnswer(key, text)
    }
    const formData = this.getFormJSON(form)
    const customFieldsToStore = [
      "birth",
      "zip",
      "address",
      "dob",
      "date_of_birth",
      "datetime",
      "optin_dt",
      "social",
      "home_phone",
      "work_phone",
      "home_ownership",
      "drivers_license",
      "drivers_license_state",
      "employer_name",
      "months_employed",
      "monthly_income",
      "next_pay_date",
      "second_pay_date",
      "bank_routing_number",
      "bank_name",
      "bank_account_number",
      "months_at_bank",
      "months_at_address",
    ]
    customFieldsToStore.map((field) => {
      const val = formData[`lead[custom_fields[${field}]]`]
      if (val) saveToQuestionsStore(field, val)
    })
    const leadFieldsToStore = ["email", "first_name", "last_name", "phone", "zip"]
    leadFieldsToStore.map((field) => {
      let val = formData[`lead[${field}]`]
      if (field === "zip") val = formData[`lead[custom_fields[${field}]]`]
      if (val) saveToUserStore(field, val)
    })
    await this.trackerModule.track("lead_form_submit", {
      ...this.questionTrackingData,
      tracking_form_id: this.trackingLeadFormId,
      tracking_form_fields: this.trackingFormFields,
      form_data: formData,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href,
      lead_form_id: this.idValue,
    })
    if (this.checkLeaptheory()) {
      setTimeout(() => {
        this.enableLeaptheory(true)
      }, 250)
    }
    if (this.checkMoneylionMarketplace()) {
      setTimeout(() => {
        this.enableMoneylionMarketplace(this.element.getAttribute("data-enable-mm-marketplace-loan"))
      }, 250)
    }
  }

  trackSubmitSuccess() {
    this.trackerModule.track("lead_form_submit_success", {
      ...this.questionTrackingData,
      tracking_form_id: this.trackingLeadFormId,
      tracking_form_fields: this.trackingFormFields,
      lead_form_id: this.idValue,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href,
    })
  }

  trackSubmitError(errors) {
    this.trackerModule.track("lead_form_submit_error", {
      ...this.questionTrackingData,
      tracking_form_id: this.trackingLeadFormId,
      tracking_form_fields: this.trackingFormFields,
      errors: errors,
      lead_form_id: this.idValue,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href,
    })
  }

  submitForm(event) {
    this.disableSubmitButton()
    this.clearErrors()
    if (event) event.preventDefault()
    if (this.validatePresence()) {
      this.trackSubmit(this.formTarget)
      if (this.checkSkyflow()) this.enableSw()
      if (this.checkSkyflowExtended()) this.enableSwExtended()
      if (this.checkEvenfinancial()) this.enableEvenfinancial(true)
      if (this.checkNextinsure()) this.enableNextinsure(true)
      if (this.checkTransparently()) this.enableTransparently(true)
      Rails.fire(this.formTarget, "submit")
    } else {
      this.enableSubmitButton()
    }
  }

  onSubmitSuccess(event) {
    this.enableSubmitButton()
    console.log("success", event)
    this.trackSubmitSuccess()
    let [data, status, xhr] = event.detail
    console.log("xhr?.response", xhr?.response)
    const lead = JSON.parse(xhr?.response)?.lead
    console.log("leadid", lead?.id)
    setCookie("lead_id", lead?.id)
    const popupUrl = this.submitPopupUrlValue
    const redirectUrlVal = this.submitRedirectUrlValue
    const isFormDataParamsPassingTurnedOff = () => {
      console.log("this.element", this.element)
      console.log("this.element.hasAttribute", this.element.hasAttribute("data-turn-off-form-data-params-passing"))
      return (
        this.element.hasAttribute("data-turn-off-form-data-params-passing") &&
        this.element.getAttribute("data-turn-off-form-data-params-passing") == "nopass"
      )
    }
    const getFormUrlParams = (dataObject) => {
      if (!dataObject) return new URLSearchParams({}).toString()

      const formUrlParams = {}
      const formUrlWhitelist = isFormDataParamsPassingTurnedOff() ? [] : ["first_name", "last_name", "email", "phone"]
      const formCustomFieldsWhitelist = isFormDataParamsPassingTurnedOff() ? [] : ["zip"]
      const isCustomFieldsString = typeof dataObject?.custom_fields == "string"
      const isCustomFieldsObject = typeof dataObject?.custom_fields == "object"
      const customLeadFields = isCustomFieldsString
        ? JSON.parse(dataObject?.custom_fields || "{}")
        : isCustomFieldsObject
        ? dataObject?.custom_fields || {}
        : {}
      console.log("[formUrlWhitelist, formCustomFieldsWhitelist]", [formUrlWhitelist, formCustomFieldsWhitelist])
      Object.keys(dataObject).map((key) => {
        if (formUrlWhitelist.includes(key) && dataObject[key]) formUrlParams[key] = dataObject[key]
      })
      Object.keys(customLeadFields).map((key) => {
        if (formCustomFieldsWhitelist.includes(key) && customLeadFields[key]) formUrlParams[key] = customLeadFields[key]
      })
      return new URLSearchParams(formUrlParams).toString()
    }
    this.switchForm()
    const popupRedirectUrl = popupUrl === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : popupUrl
    const redirectUrl = redirectUrlVal === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : redirectUrlVal
    if (popupRedirectUrl && popupRedirectUrl.length > 0) {
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(this.linkModule.addParamsToUrl(popupRedirectUrl)(getFormUrlParams(lead))),
        true
      )
    }
    if (redirectUrl && redirectUrl.length > 0) {
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(this.linkModule.addParamsToUrl(redirectUrl)(getFormUrlParams(lead)))
      )
    }
  }

  onSubmitError(event) {
    this.enableSubmitButton()
    let [data, status, xhr] = event.detail
    const rawErrors = xhr?.response
    const errors = rawErrors && rawErrors.lenght > 0 && JSON.parse(rawErrors)?.errors
    this.trackSubmitError(errors)
    const fields = this.formTarget.querySelectorAll("input, textarea, select")

    Object.entries(errors).map(([key, val]) => {
      const invalidFields = Array.from(fields).filter((field) => {
        return [`lead[${key}]`, `lead[custom_fields[${key}]]`].includes(field.getAttribute("name"))
      })
      console.log("invalidFields", invalidFields)
      const errClasses = `border border-error`.split(" ")
      invalidFields.forEach((f) => {
        f.classList.add(...errClasses)
        const errBlock = f.parentNode.querySelector(`span#error_lead_${key}`)
        errBlock.innerHTML = val
      })
      // this.errorMessageTarget.innerHTML = ``;
    })
  }

  switchForm() {
    const inactiveClasses = ["hidden"]
    console.log("switchForm")
    this.triggerLoader(true)
    if (this.hasChainTarget) {
      this.formTarget.classList.add(...inactiveClasses)
      this.chainTarget.classList.remove(...inactiveClasses)
    }

    if (this.parentQuestionController) {
      console.log("THE LEAD FORM IS INSIDE QUESTION", this.parentQuestionController)
      this.parentQuestionController.handleAnswer()
    }

    if (this.parentModal) this.modalController.toggleOpen(false)
  }

  validatePresence() {
    let requiredFieldSelectors = "select:required, textarea:required, input:required"
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors)
    let isValid = true
    const errClasses = `border border-error`.split(" ")
    requiredFields.forEach((field) => {
      isValid = isValid && field.reportValidity()
      if (!field.disabled && !field.value.trim()) {
        field.classList.add(...errClasses)
        const errBlock = field.parentNode.querySelector(`span[id^='error']`)
        if (errBlock) errBlock.innerHTML = "This field is required."
        const toolTip = tippy(field, {
          interactive: true,
          animation: "fade",
          content: "Please Fill Out This Field",
          trigger: "click",
        })
        toolTip?.show()
        field?.focus()

        isValid = false
      }
    })

    return isValid
  }

  triggerLoader(state) {
    if (!this.hasSubmitButtonTarget) return

    if (state) {
      this.submitButtonTarget.classList.add("disabled:opacity-50", "pointer-events-none")
      if (this.hasSubmitLoaderTarget) this.submitLoaderTarget.classList.remove("hidden")
    } else {
      this.submitButtonTarget.classList.remove("disabled:opacity-50", "pointer-events-none")
      if (this.hasSubmitLoaderTarget) this.submitLoaderTarget.classList.add("hidden")
    }
  }

  clearErrors() {
    this.errorMessageTarget.innerHTML = ""
    const fields = this.formTarget.querySelectorAll("input, textarea, select")
    Array.from(fields).forEach((f) => {
      f.classList.remove("border-error")
      const errBlock = f.parentNode.querySelector(`span[id^='error']`)
      if (errBlock) f.parentNode.querySelector(`span[id^='error']`).innerHTML = ""
    })
  }

  checkSkyflow() {
    const SKYFLOW_ATTR_ENABLED_STATUS = "enable"
    const isSkyflowEnabledOnForm =
      this.element.hasAttribute("data-enable-skyflow") &&
      this.element.getAttribute("data-enable-skyflow") === SKYFLOW_ATTR_ENABLED_STATUS
    return isSkyflowEnabledOnForm
  }

  enableSw() {
    if (this.formTarget && this.formTarget?.action)
      this.formTarget.action = this.linkModule.addParamsToUrl(this.formTarget.action)(
        `sw=true&no_t=true&creds_id=${this.sfCredsValue}`
      )
  }

  checkSkyflowExtended() {
    const SKYFLOW_ATTR_ENABLED_STATUS = "enable"
    const isSkyflowEnabledOnForm =
      this.element.hasAttribute("data-enable-skyflow-extended") &&
      this.element.getAttribute("data-enable-skyflow-extended") === SKYFLOW_ATTR_ENABLED_STATUS
    return isSkyflowEnabledOnForm
  }

  enableSwExtended() {
    if (this.formTarget && this.formTarget?.action) {
      this.formTarget.action = this.linkModule.addParamsToUrl(this.formTarget.action)(
        `sw_extended=true&no_t=true&creds_id=${this.sfCredsValue}`
      )
    }
    console.log("this.formTarget", this.formTarget)
    if (this.formTarget) {
      const savedQuestions = window.localStorage.getItem("questions_results")
      const parsedSavedQuestions = JSON.parse(savedQuestions)
      Object.entries(parsedSavedQuestions).forEach((el) => {
        const input = document.createElement("input")
        input.setAttribute("type", "hidden")
        input.setAttribute("name", `lead[custom_fields[${el[0]}]]`)
        input.setAttribute("value", el[1])
        this.formTarget.appendChild(input)
      })
    }
  }

  checkEvenfinancial() {
    const EVENFIN_ATTR_ENABLED_STATUS = "enable"
    const isEvenfinEnabledOnForm =
      this.element.hasAttribute("data-enable-evenfinancial") &&
      this.element.getAttribute("data-enable-evenfinancial") === EVENFIN_ATTR_ENABLED_STATUS
    return isEvenfinEnabledOnForm
  }

  enableEvenfinancial(state = false) {
    window.localStorage.setItem("renderEvenFinancialWall", state)
  }

  checkNextinsure() {
    const NEXTINSURE_ATTR_ENABLED_STATUS = "enable"
    const isNextinsureEnabledOnForm =
      this.element.hasAttribute("data-enable-nextinsure") &&
      this.element.getAttribute("data-enable-nextinsure") === NEXTINSURE_ATTR_ENABLED_STATUS
    return isNextinsureEnabledOnForm
  }

  enableNextinsure(state = false) {
    window.localStorage.setItem("renderNextinsureWall", state)
  }


  checkTransparently() {
    const TLY_ATTR_ENABLED_STATUS = "enable"
    const isTlyEnabledOnForm =
      this.element.hasAttribute("data-enable-transparently") &&
      this.element.getAttribute("data-enable-transparently") === TLY_ATTR_ENABLED_STATUS
    return isTlyEnabledOnForm
  }

  enableTransparently(state = false) {
    window.localStorage.setItem("renderTransparentlyWall", state)
  }

  checkLeaptheory() {
    const LEAP_ATTR_ENABLED_STATUS = "enable"
    const isLeapEnabledOnForm =
      this.element.hasAttribute("data-enable-leaptheory") &&
      this.element.getAttribute("data-enable-leaptheory") === LEAP_ATTR_ENABLED_STATUS
    return isLeapEnabledOnForm
  }

  enableLeaptheory(state = false) {
    const leaptheoryModule = new LeaptheoryModule()
    if (state) leaptheoryModule.sendLead()
  }

  checkMoneylionMarketplace() {
    const MM_ATTR_ENABLED_STATUS = ["enable", "no_redirect"]
    const isMMEnabledOnForm =
      this.element.hasAttribute("data-enable-mm-marketplace-loan") &&
      MM_ATTR_ENABLED_STATUS.includes(this.element.getAttribute("data-enable-mm-marketplace-loan"))
    console.log('checkMOneyion', isMMEnabledOnForm)
    return isMMEnabledOnForm
  }

  enableMoneylionMarketplace(state = false) {
    const moneylionMarketplaceModule = new MoneylionMarketplaceModule()
    const performApi = state !== false
    const performRedirect = state === 'enable'
    if (performApi) moneylionMarketplaceModule.sendLead(performRedirect)
  }

  getQuestionController() {
    const parentQuestion = this.element.closest(
      '[data-controller="chain--answer--lead-form--component chain--answer--component"]'
    ) // check if lead form inside question
    if (parentQuestion) {
      this.parentQuestionController = this.leadformQuestionController(parentQuestion)
    }
  }

  leadformQuestionController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "chain--answer--lead-form--component")
  }

  disableSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add("disabled:opacity-50", "pointer-events-none")
    }
  }

  enableSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.remove("disabled:opacity-50", "pointer-events-none")
    }
  }
}
